import React  from 'react';
import { LineChart,Bar,BarChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

export default function GraphiqueCM()
{
    const data = [
        {
          name: '< 2 fautes',
          an1987: 12.9,
          an2021: 1.9,
          site: 9.1
       
        },
        {
          name: '2-5 fautes',
          an1987: 17.8,
          an2021: 5.1,
          site : 12.5
        },
        {
          name: '5-10 fautes',
          an1987: 27.4,
          an2021: 12.7,
          site: 11.7
        },
        {
          name: '10-15 fautes',
          an1987: 15.7,
          an2021: 15.1, 
           site : 22.5
        },
        {
          name: '15-25 fautes',
          an1987: 19.3,
          an2021: 35.5,
          site: 19.2
      
      
        },
        {
          name: '≥25 fautes',
          an1987: 6.9,
          an2007: 13.2,
          an2015: 22.7,
          an2021: 27.5,
          site: 25
      
        },
      ];

      return  <ResponsiveContainer width="100%" height={300}>
        <BarChart
         
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis label={{ value: 'Pourcentage', angle: -90, position: 'insideLeft' }}  />
          <Tooltip />
          <Legend />
          <Bar  dataKey="an1987" fill="#C884d8" />
          <Bar   dataKey="an2021" fill="#8994d8" />
          <Bar   dataKey="site" fill="#f556c0" />
       
        </BarChart>
      </ResponsiveContainer>
    

}