let donneesConcoursOrthographe =
    [  
        {
            id: 330,
            titre: "Quiz compléter les phrases - facile (nouveau)",
            liste: [{ type: 'textetrou', id: 10330, titre: "textetrou" }, { type: 'textetrou', id: 10331, titre: "Musso" }, { type: 'textetrou', id: 10332, titre: "textetrou" }
            ]
        }, 
        {
            id: 340,
            titre: "Quiz les pièges orthographiques - intermédiaire (nouveau)",
            liste: [{ type: 'textetrou', id: 10340, titre: "textetrou" }, { type: 'textetrou', id: 10341, titre: "Musso" }, { type: 'textetrou', id: 10342, titre: "textetrou" }
            ]
        }, 
        
        {
            id: 130,
            titre: "Trouver toutes les fautes dans des extraits de livres récents - facile",
            liste: [{ type: 'phraseerreur', id: 10130, titre: "Grimaldi" }, { type: 'phraseerreur', id: 10131, titre: "Musso" }, { type: 'phraseerreur', id: 10132, titre: "Levy" }
            ]
        }, 
        {
            id: 290,
            titre: "Quiz grammaire et vocabulaire 1 - facile",

            liste: [{ type: 'qcm', id: 10290, titre: "qcm" }, { type: 'qcmmelange', id: 10291, titre: "vocabulaire" }, { type: 'qcm', id: 10292, titre: "qcm" }
            ]
        },
      
      
      
        {
            id: 210,
            titre: "Quiz écrire des mots (audio) - facile",
            liste: [  { type: 'orthographemot', id: 10210, titre: "mots" },{ type: 'phrasetrou', id: 10211, titre: "trous" }, { type: 'orthographemot', id: 10212, titre: "mots" }
            ]
        },
        {
            id: 90,
            titre: "Dictées et orthographe des mots (audio) - facile",

            liste: [{ type: 'dictee', id: 10090, titre: "dictée" }, { type: 'orthographemot', id: 10091, titre: "mot" },{ type: 'dictee', id: 10092, titre: "dictée" }
            ]
        },  {
            id: 280,
            titre: "Quiz grammaire et vocabulaire 2 - intermédiaire",

            liste: [{ type: 'qcm', id: 10280, titre: "qcm" }, { type: 'qcmmelange', id: 10281, titre: "vocabulaire" }, { type: 'qcm', id: 10282, titre: "qcm" }
            ]
        },
          {
            id: 50,
            titre: "Grammaire, orthographe et mémoire - intermédiaire",

            liste: [{ type: 'verbeconjugaison', id: 10050, titre: "conjugaison" }, { type: 'phraseerreur', id: 10051, titre: "erreur" },{ type: 'memoire', id: 10052, titre: "mémoire" },{ type: 'phrasetrou', id: 10053, titre: "trou" }
            ]
        }, 
        {
            id: 60,
            titre: "Dictées et orthographe des mots (audio) 1 - intermédiaire",

            liste: [{ type: 'dictee', id: 10060, titre: "dictée" }, { type: 'orthographemot', id: 10061, titre: "mot" },{ type: 'dictee', id: 10062, titre: "dictée" }
            ]
        },
         {
            id: 70,
            titre: "Dictées et orthographe des mots (audio) 2 - intermédiaire",

            liste: [{ type: 'dictee', id: 10070, titre: "dictée" }, { type: 'orthographemot', id: 10071, titre: "mot" },{ type: 'dictee', id: 10072, titre: "dictée" }
            ]
        }, 
         {
            id: 120,
            titre: "Test conjugaison passé/présent/futur - intermédiaire",
            liste: [{ type: 'verbeconjugaison', id: 10120, titre: "passé" }, { type: 'verbeconjugaison', id: 10121, titre: "présent" }, { type: 'verbeconjugaison', id: 10122, titre: "futur" }
            ]
        },
          {
            id: 170,
            titre: "Test écrire sans faute (audio) - intermédiaire",
            liste: [  { type: 'orthographemot', id: 10170, titre: "phrases" },{ type: 'orthographemot', id: 10171, titre: "mots" }, { type: 'orthographemot', id: 10172, titre: "phrases" }
            ]
        },  
         {
            id: 200,
            titre: "Test repérer les erreurs - intermédiaire",
            liste: [  { type: 'phraseerreur', id: 10200, titre: "erreur" },{ type: 'phrasetrou', id: 10201, titre: "trous" }, { type: 'phraseerreur', id: 10202, titre: "erreur" }
            ]
        },
        
        {
            id: 40,
            titre: "Grammaire, orthographe et mémoire - difficile",

            liste: [{ type: 'phraseerreur', id: 10040, titre: "erreur" }, { type: 'memoire', id: 10042, titre: "mémoire" }, { type: 'phrasetrou', id: 10041, titre: "trou" }, { type: 'verbeconjugaison', id: 10043, titre: "conjugaison" }
            ]
        },
     
        {
            id: 310,
            titre: "Quiz correction des grands classiques- difficile",
            liste: [{ type: 'phraseerreur', id: 10310, titre: "Stendhal" }, { type: 'phraseerreur', id: 10311, titre: "Maupassant" }
            ]
        },
      
      
        {
            id: 180,
            titre: "Défi écrire sans faute (audio) - difficile",
            liste: [  { type: 'orthographemot', id: 10180, titre: "phrases" },{ type: 'orthographemot', id: 10181, titre: "mots" }, { type: 'orthographemot', id: 10182, titre: "phrases" }
            ]
        },
       
     
      
        {
            id: 270,
            titre: "Quiz corriger les textes- difficile",

            liste: [{ type: 'phraseerreur', id: 10270, titre: "fautes" }, { type: 'phraseerreur', id: 10271, titre: "fautes" }
            ]
        },
       
         {
            id: 260,
            titre: "Quiz sur l'orthographe trop souvent négligée - très difficile",

            liste: [{ type: 'phrasetrou', id: 10260, titre: "trou" }, { type: 'phrasetrou', id: 10261, titre: "trou" },  { type: 'phrasetrou', id: 10262, titre: "trou" },  { type: 'phrasetrou', id: 10263, titre: "trou" }
            ]
        },
       

        
        

    ]

export default donneesConcoursOrthographe;